import React from 'react';

import Fade from 'react-reveal/Fade'

import bg from '../../img/AboutUs/bg.png'
import ice from '../../img/AboutUs/ice3.png'

import { useTranslation } from "react-i18next";

function OurDeposit () {

    const { t } = useTranslation();

    return(
        <div className='max-w-[1920px] mx-auto overflow-hidden relative mt-[80px] xl:mt-[200px]'>
            <img src={bg} alt="" className='w-full absolute'/>
            <div className='max-w-[1220px] mx-[20px] xl:mx-auto relative xl:mt-[200px] xl:h-[800px]'>
                <p className='max-w-[335px] xl:max-w-[537px] text-white uppercase russo-one text-[20px] xl:text-[32px]'>{t('aboutus.29')}</p>
                <Fade right>
                    <img src={ice} alt="" className='xl:absolute mt-[40px] xl:mt-0 top-[-130px] right-[-150px]'/>
                </Fade>   
            </div>
        </div>
    );
}

export default OurDeposit;