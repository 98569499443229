import React from "react";
import $ from 'jquery';
import { useEffect, useState } from "react";

import { images } from "./imagesToLoad";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Header';

import Home from './pages/Home';
import MarginTrading from "./pages/MarginTrading";
import SLIP from "./pages/SLIP";
import CashManagement from "./pages/CashManagement";
import TradingInstruments from "./pages/TradingInstruments";
import AccountTypes from "./pages/AccountTypes";
import AboutUs from "./pages/AboutUs";

function App() {
  const [ isLoading, setIsLoading ] = useState(true);

  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };

  useEffect(() => {
    isLoading && $('body').addClass("ovf-hidden");
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log("Failed to load images", err)
        setIsLoading(false);
      });
    // eslint-disable-next-line  
  }, []);

  useEffect(() => {
    !isLoading && $('body').removeClass("ovf-hidden");
    !isLoading && $('#loader').addClass("hide");
  }, [isLoading]); 
  return (
  <Router>
      <Header/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/margintrading" element={<MarginTrading />} />
          <Route path="/slip" element={<SLIP />} />
          <Route path="/cashmanagment" element={<CashManagement/>} />
          <Route path="/trading" element={<TradingInstruments/>} />
          <Route path="/account" element={<AccountTypes/>} />
          <Route path="/about" element={<AboutUs/>} />
        </Routes>
    </Router>
  );
}

export default App;