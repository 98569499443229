import React from "react";

import Fade from "react-reveal/Fade";

import buy from '../../img/Home/buyandsell.png'

import { useTranslation } from "react-i18next";

function BuyAndSelling () {

    const { t } = useTranslation();
      
    return(
        <div className="bg-[#000C17] relative z-50 mt-[-5px] overflow-hidden">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto pt-[120px] xl:pt-[90px]">
                <div className="flex flex-col xl:flex-row justify-between xl:items-end">
                    <div>
                        <Fade duration={1000}>
                            <p className="russo-one text-[24px] md:text-[48px] text-white">{t('main.15')}</p>
                            <p className="roboto text-[14px] xl:text-[16px] text-white font-[300] mt-[20px] xl:max-w-[600px]">{t('main.16')}</p>
                            <p className="text-[#00FFF0] uppercase mt-[60px] text-[16px] xl:text-[20px] russo-one">{t('main.17')}</p>
                            <p className="roboto text-[14px] xl:text-[16px] text-white font-[300] mt-[20px] xl:max-w-[600px]">{t('main.18')}</p>
                        </Fade>
                    </div> 
                    <div>
                        <Fade right><img src={buy} alt="" className="mx-auto"/></Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuyAndSelling;