import React from 'react';

import Fade from 'react-reveal/Fade'

import svg from '../../img/AccountTypes/svg.svg'

import ice1 from '../../img/AboutUs/ice1.png'
import main from '../../img/AboutUs/main.png'

import { useTranslation } from "react-i18next";

function Main () {

    const { t } = useTranslation();

    return(
        <div className='max-w-[1920px] mx-auto overflow-hidden'>
            <div className="bg-[#000C17] max-w-[1220px] mx-[20px] xl:mx-auto relative">
                <div className='flex flex-col xl:flex-row justify-between'>
                    <div className='pt-[80px] xl:pt-[140px]'>
                        <p className='text-white russo-one text-[32px] xl:text-[48px]'>{t('aboutus.1')}</p>
                        <p className='text-white xl:max-w-[600px] font-[300] roboto mt-[40px] text-[14px] xl:text-[16px]'>{t('aboutus.2')}</p>
                        <p className='mt-[80px] text-[#00FFF0] roboto font-[500] xl:max-w-[600px] text-[16px] xl:text-[20px]'>{t('aboutus.3')}</p>
                        <div className='flex mt-[40px] items-start'>
                            <img src={svg} alt="" className='mt-[7px]'/>
                            <div className='ml-[30px]'>
                                <p className='roboto font-[500] text-white text-[16px] xl:text-[20px]'>{t('aboutus.4')}</p>
                                <p className='xl:max-w-[552px] text-white font-[300] text-[14px] xl:text-[16px] roboto mt-[10px]'>{t('aboutus.5')}</p>
                            </div>
                        </div>
                        <div className='flex mt-[40px] items-start'>
                            <img src={svg} alt="" className='mt-[7px]'/>
                            <div className='ml-[30px]'>
                                <p className='roboto font-[500] text-white text-[16px] xl:text-[20px]'>{t('aboutus.6')}</p>
                                <p className='xl:max-w-[552px] text-white font-[300] text-[14px] xl:text-[16px] roboto mt-[10px]'>{t('aboutus.7')}</p>
                            </div>
                        </div>
                        <div className='flex mt-[40px] items-start'>
                            <img src={svg} alt="" className='mt-[7px]'/>
                            <div className='ml-[30px]'>
                                <p className='roboto font-[500] text-white text-[16px] xl:text-[20px]'>{t('aboutus.8')}</p>
                                <p className='xl:max-w-[552px] text-white font-[300] text-[14px] xl:text-[16px] roboto mt-[10px]'>{t('aboutus.9')}</p>
                            </div>
                        </div>
                    </div>
                    <div className='xl:pt-[60px] mr-[-100px]'>
                        <div className="right-blur mr-[-100px]"></div>
                        <Fade delay={500}>
                            <img src={main} alt="" />
                        </Fade>
                        <Fade right>
                            <img src={ice1} alt="" className='absolute xl:top-[500px] right-[-100px] bottom-0 z-[999] xl:right-[-600px]'/>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default Main;