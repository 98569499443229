import React, { useEffect } from "react";
import Main from "../components/AccountTypes/Main";
import AccountComparison from "../components/AccountTypes/AccountComparison";

function AccountTypes () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#000C17]">
            <Main/>
            <AccountComparison/>
        </div>
    );
}

export default AccountTypes;