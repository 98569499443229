import React, { useState, useEffect } from "react";

import Fade from "react-reveal/Fade";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import swiper1 from '../../img/Home/swiper/swiper1.png'
import swiper2 from '../../img/Home/swiper/swiper2.png'
import swiper3 from '../../img/Home/swiper/swiper3.png'
import swiper4 from '../../img/Home/swiper/swiper4.png'

import { useTranslation } from "react-i18next";

const slidesData = [
    {
        id: 1,
        title: 'main.25',
        text: 'main.26',
        img: swiper1
    },
    {
        id: 2,
        title: 'main.27',
        text: 'main.28',
        img: swiper2
    },
    {
        id: 3,
        title: 'main.29',
        text: 'main.30',
        img: swiper3
    },
    {
        id: 4,
        title: 'main.31',
        text: 'main.32',
        img: swiper4
    },
  ];

function SwiperS () {

    const { t } = useTranslation();

    const [swiper, setSwiper] = useState({});

    const [activeSlide, setActiveSlide] = useState(0);

    const [isActiveTimer, setisActiveTimer] = useState(true);

    const nextSlide = () => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % 4); // Cycle from 0 to 4
    };
  
    const prevSlide = () => {
      setActiveSlide((prevSlide) => (prevSlide - 1 + 4) % 4); // Cycle from 4 to 0
    };
    
    
    useEffect(() => {
        if(isActiveTimer){
            const intervalId = setInterval(() => {
                nextSlide();
                swiper.slideNext();
            }, 3000);
            return () => clearInterval(intervalId);
        }
    },);
    
    return(
        <div className="bg-[#000C17] relative z-50 mt-[-5px] overflow-hidden">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className='max-w-[1220px] mx-auto flex justify-end gap-[10px]'>
                    <div onClick={() => { swiper.slidePrev(); prevSlide(); setisActiveTimer(false); }} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <g filter="url(#filter0_bf_1545_96)">
                                <rect x="4" y="4" width="40" height="40" rx="20" fill="white" fill-opacity="0.06"/>
                                <rect x="4.5" y="4.5" width="39" height="39" rx="19.5" stroke="white"/>
                            </g>
                            <path d="M15 24L20 26.8868L20 21.1132L15 24ZM33 23.5L19.5 23.5L19.5 24.5L33 24.5L33 23.5Z" fill="white"/>
                            <defs>
                                <filter id="filter0_bf_1545_96" x="-18" y="-18" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="11"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1545_96"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1545_96" result="shape"/>
                                <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_1545_96"/>
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    <div onClick={() => { swiper.slideNext(); nextSlide(); setisActiveTimer(false);  }} className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <g filter="url(#filter0_bf_1545_93)">
                                <rect x="44" y="44" width="40" height="40" rx="20" transform="rotate(180 44 44)" fill="white" fill-opacity="0.06"/>
                                <rect x="43.5" y="43.5" width="39" height="39" rx="19.5" transform="rotate(180 43.5 43.5)" stroke="white"/>
                            </g>
                            <path d="M33 24L28 21.1132L28 26.8867L33 24ZM15 24.5L28.5 24.5L28.5 23.5L15 23.5L15 24.5Z" fill="white"/>
                            <defs>
                                <filter id="filter0_bf_1545_93" x="-18" y="-18" width="84" height="84" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feGaussianBlur in="BackgroundImageFix" stdDeviation="11"/>
                                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1545_93"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1545_93" result="shape"/>
                                <feGaussianBlur stdDeviation="2" result="effect2_foregroundBlur_1545_93"/>
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </div>
                <Swiper
                    modules={[Navigation]}
                    className="mt-[20px]"
                    slidesPerView={1}
                    onInit={(e) => {
                        setSwiper(e);
                    }}
                    breakpoints={{
                        300: {
                            slidesPerView: 1
                        },
                        1280: {
                            slidesPerView: 4,
                        },
                    }}
                >
                    {slidesData.map((slide) => (
                        <SwiperSlide className="cursor-pointer" key={slide.id} onClick={() => {setActiveSlide(slide.id - 1); setisActiveTimer(false);}}>
                            <div className="swiper-main xl:w-[290px] h-[458px] overflow-hidden">
                                <div className="flex flex-col">
                                    <div className="mx-[30px] mt-[30px] xl:mt-[60px]">
                                        <p className="russo-one text-[#00FFF0] text-[14px] xl:text-[16px]">{t(slide.title)}</p>
                                        <div className="overflow-hidden">
                                            {activeSlide === slide.id -1 && (
                                                <Fade top><p className="hidden xl:block roboto text-white text-[14px] xl:text-[16px] mt-[12px] font-[300]">{t(slide.text)}</p></Fade>
                                            )}
                                        </div>
                                        <p className="block xl:hidden roboto text-white text-[14px] xl:text-[16px] mt-[12px] font-[300]">{t(slide.text)}</p>
                                    </div>
                                    <div className={`transition-all duration-1000 mt-[66px] mx-[30px] ${activeSlide === slide.id -1 ? 'mt-[-20px] xl:mt-0' : 'mt-[0px] xl:mt-[66px]'}`}>
                                        <img src={slide.img} alt="" className={`transition-all duration-1000 ${activeSlide === slide.id -1 ? 'scale-50 xl:scale-75' : 'xl:scale-100 scale-75'}`}/>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

export default SwiperS;