import React from 'react';

import Fade from 'react-reveal/Fade'

import cube1 from '../../img/MarginTrading/cube1.png'
import cube2 from '../../img/MarginTrading/cube2.png'
import cube3 from '../../img/MarginTrading/cube3.png'
import cube4 from '../../img/MarginTrading/cube4.png'

import { useTranslation } from "react-i18next";

function HowStart () {

    const { t } = useTranslation();

    return(
        <div className="bg-[#000C17] relative pt-[80px] xl:pt-[160px] mt-[-5px] pb-[5px]">
            <div className='max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col'>
                <h1 className="russo-one text-white text-[20px] md:text-[24px] xl:text-[48px] mx-auto">{t('margintrading.16')}</h1>
                <div className='grid md:grid-cols-2 xl:grid-cols-4 pt-[20px]'>
                    <Fade bottom>
                        <div className='flex flex-col items-center'>
                            <img src={cube1} alt=""/>
                            <p className='max-w-[290px] text-white text-[14px] xl:text-[16px] roboto text-center mt-[20px]'>{t('margintrading.17')}</p>
                        </div>
                    </Fade>
                    <Fade delay={500} bottom>
                        <div className='flex flex-col items-center'>
                            <img src={cube2} alt=""/>
                            <p className='max-w-[290px] text-white text-[14px] xl:text-[16px] roboto text-center mt-[20px]'>{t('margintrading.18')}</p>
                        </div>
                    </Fade>
                    <Fade delay={700} bottom>
                        <div className='flex flex-col items-center'>
                            <img src={cube3} alt=""/>
                            <p className='max-w-[290px] text-white text-[14px] xl:text-[16px] roboto text-center mt-[20px]'>{t('margintrading.19')}</p>
                        </div>
                    </Fade>
                    <Fade delay={900} bottom>
                        <div className='flex flex-col items-center'>
                            <img src={cube4} alt=""/>
                            <p className='max-w-[290px] text-white text-[14px] xl:text-[16px] roboto text-center mt-[20px]'>{t('margintrading.20')}</p>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    );
}

export default HowStart;