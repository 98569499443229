import React from "react";

import { Link } from "react-router-dom";

import explore from '../../img/Home/explore.jpg'
import aexplore from '../../img/Home/apple_explore.png'
import ice from '../../img/Home/ice.png'
import check from '../../img/Home/sheck.svg'

import Fade from "react-reveal/Fade";

import { useTranslation } from "react-i18next";

function ExploreTradeIdeas () {

    const { t } = useTranslation();
      
    return(
        <div className="bg-[#000C17] relative z-50 overflow-hidden mt-[-5px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto">
                <div className="h-[589px] w-full rounded-[20px] relative">
                    <img src={explore} alt="" className="object-cover w-full h-full absolute rounded-[20px]"/>
                    <div className="flex flex-col-reverse xl:flex-row xl:justify-end">
                        <div className="xl:static z-50">
                            <img src={ice} alt="" className="absolute xl:left-[100px] bottom-[150px] xl:bottom-[400px] scale-50 xl:scale-100"/>
                            <img src={aexplore} alt="" className="moving-block3 md:right-0 xl:left-[-150px] absolute bottom-0"/>
                        </div>
                        <div className="z-50 xl:mr-[70px] xl:mt-[80px] mx-[20px] xl:mx-0">
                            <Fade duration={1000}>
                                <p className="text-white russo-one text-[20px] md:text-[24px] xl:text-[48px]">{t('main.19')}</p>
                                <p className="xl:max-w-[526px] text-[14px] xl:text-[16px] text-white roboto font-[300] mt-[20px]">{t('main.20')}</p>
                                <div className="flex gap-[20px] items-center mt-[20px] xl:mt-[40px]">
                                    <img src={check} alt="" />
                                    <p className="roboto text-[14px] xl:text-[16px] text-white">{t('main.21')}</p>
                                </div>
                                <div className="flex gap-[20px] items-center mt-[15px] xl:mt-[24px]">
                                    <img src={check} alt="" />
                                    <p className="roboto text-[14px] xl:text-[16px] text-white">{t('main.22')}</p>
                                </div>
                                <div className="flex gap-[20px] items-center mt-[15px] xl:mt-[24px]">
                                    <img src={check} alt="" />
                                    <p className="roboto text-[14px] xl:text-[16px] text-white">{t('main.23')}</p>
                                </div>
                                <Link to='https://user.frax-trade.net'>
                                    <div className="button relative z-50 mt-[30px] xl:mt-[60px] cursor-pointer">
                                        <p className="russo-one text-[14px] xl:text-[20px] text-white">{t('main.24')}</p>
                                    </div>
                                </Link>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExploreTradeIdeas;