import React from 'react';

import { Link } from 'react-router-dom';

import Fade from 'react-reveal/Fade';

import Footer from "../Footer";

import component from '../../img/SLIP/main.png'
import footer from '../../img/Home/footer.png'

import svg1 from '../../img/SLIP/svg1.svg'
import svg2 from '../../img/SLIP/svg2.svg'
import svg3 from '../../img/SLIP/svg3.svg'
import svg4 from '../../img/SLIP/svg4.svg'
import svg5 from '../../img/SLIP/svg5.svg'

import { useTranslation } from "react-i18next";

const data = [
    {

        title: 'slip.9',
        svg: svg1,
        text: 'slip.10'
    },
    {
        title: 'slip.11',
        svg: svg2,
        text: 'slip.12'
    },
    {
        title: 'slip.13',
        svg: svg3,
        text: 'slip.14'
    },
    {
        title: 'slip.15',
        svg: svg4,
        text: 'slip.16'
    },
    {
        title: 'slip.17',
        svg: svg5,
        text: 'slip.18'
    },
]

function Main () {

    const { t } = useTranslation();

    return(
        <div className="bg-[#000C17] max-w-[1920px] mx-auto overflow-hidden relative">
            <div className='max-w-[1280px] mx-[20px] xl:mx-auto z-50 relative'>
                <div className='flex flex-col xl:flex-row pt-[100px] justify-between'>
                    <div className='mt-[40px]'>
                        <Fade duration={2000}>
                            <h1 className="russo-one text-white text-[24px] md:text-[32px] xl:text-[36px] max-w-[610px]">{t('slip.1')}</h1>
                            <p className='roboto text-white text-[14px] !opacity-[0.7] xl:text-[20px] font-[500] mt-[20px] xl:max-w-[600px]'>{t('slip.2')}</p>
                            <p className='font-[500] xl:max-w-[600px] text-[#00FFF0] roboto text-[16px] xl:text-[20px] mt-[20px] xl:mt-[60px]'>{t('slip.3')}</p>
                            <p className='font-[300] xl:max-w-[600px] text-white roboto text-[14px] xl:text-[16px] mt-[20px] xl:mt-[40px]'>{t('slip.4')}</p>
                            <p className='font-[500] xl:max-w-[600px] text-[#00FFF0] roboto text-[16px] xl:text-[20px] mt-[20px] xl:mt-[60px]'>{t('slip.5')}</p>
                            <p className='font-[300] xl:max-w-[600px] text-white roboto text-[14px] xl:text-[16px] mt-[20px] xl:mt-[40px]'>{t('slip.6')}</p>
                        </Fade>
                    </div>
                    <div className='mt-[40px] xl:mt-0 xl:absolute xl:-right-0 2xl:-right-1/4 top-0 overflow-hidden'>
                        <Fade right><img src={component} alt="" className='mx-auto'/></Fade>
                    </div>
                </div>
            </div>
            <img src={footer} alt="" className="absolute w-full h-screen object-cover bottom-0"/>
            <div className='max-w-[1280px] mx-[20px] xl:mx-auto z-50'>
                <div className='flex flex-col pt-[80px] xl:pt-[160px] relative'>
                    <h1 className="russo-one text-white text-[20px] md:text-[24px] xl:text-[48px] mx-auto">{t('slip.7')}</h1>
                    <div className='flex flex-wrap mt-[60px] gap-[20px] xl:gap-[60px]'>
                        {data.map((data, index) => (
                            <Fade bottom>
                                <div key={index} className='block1 h-[270px]'>
                                    <img src={data.svg} alt="" className='mx-[20px] xl:mx-[40px] mt-[20px] xl:mt-[30px]'/>
                                    <p className='mx-[20px] xl:mx-[40px] mt-[20px] text-[16px] xl:text-[20px] text-[#00FFF0] uppercase russo-one'>{t(data.title)}</p>
                                    <p className='mx-[20px] xl:mx-[40px] font-[300] text-white text-[14px] xl:text-[16px] roboto mt-[15px]'>{t(data.text)}</p>
                                </div>
                            </Fade>
                        ))}
                    </div>
                    <Link to='https://user.frax-trade.net'>
                        <div className="button relative z-[500] mt-[20px] xl:mt-[60px] cursor-pointer mx-auto">
                            <p className="russo-one text-[14px] xl:text-[20px] text-white">{t('slip.8')}</p>
                        </div>
                    </Link>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Main;