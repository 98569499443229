import React, { useEffect } from "react";

import Main from "../components/CashManagement/Main";


function CashManagement () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#000C17]">
            <Main/>
        </div>
    );
}

export default CashManagement;