import React from 'react';

import blured from '../../img/MarginTrading/blured.jpg'
import moneyhand from '../../img/MarginTrading/money-hand.png'

import { useTranslation } from "react-i18next";

function CashMarginAccount () {

    const { t } = useTranslation();

    return(
        <div className="bg-[#000C17] relative pt-[80px] xl:pt-[160px] mt-[-5px] pb-[5px]">
            <div className='max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col'>
                <h1 className="russo-one text-white text-[20px] md:text-[24px] xl:text-[48px] mx-auto">{t('margintrading.39')}</h1>
                <div className='flex flex-col xl:flex-row justify-between pt-[80px]'>
                    <div className='xl:w-[600px] h-[426px] rounded-[20px] overflow-hidden relative '>
                        <img src={blured} alt="" className='object-cover w-full h-full absolute'/>
                        <div className='cash-block w-full h-full relative'>
                            <div className='mx-[40px] pt-[40px]'>
                                <p className='text-[16px] xl:text-[20px] text-[#00FFF0] uppercase russo-one'>{t('margintrading.40')}</p>
                                <p className='font-[300] text-white text-[14px] xl:text-[16px] roboto mt-[20px]'>{t('margintrading.41')}</p>
                                <p className='font-[300] text-white text-[14px] xl:text-[16px] roboto mt-[20px]'>{t('margintrading.42')}</p>
                            </div>
                        </div>
                    </div>
                    <div className='xl:w-[600px] h-[426px] rounded-[20px] overflow-hidden relative '>
                        <img src={blured} alt="" className='object-cover w-full h-full absolute'/>
                        <div className='cash-block w-full h-full relative'>
                            <div className='mx-[40px] pt-[40px]'>
                                <p className='text-[16px] xl:text-[20px] text-[#00FFF0] uppercase russo-one'>{t('margintrading.43')}</p>
                                <p className='font-[300] text-white text-[14px] xl:text-[16px] roboto mt-[20px]'>{t('margintrading.44')}</p>
                                <img src={moneyhand} alt="" className='absolute left-0 bottom-0 md:bottom-[-100px] xl:bottom-0 w-full'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CashMarginAccount;