import React from "react";

import footer from '../../img/Home/footer.png'
import check from '../../img/Home/check.svg'
import Footer from "../Footer";

import { useTranslation } from "react-i18next";

const data = [
    {
        title: 'main.34',
        text: 'main.35'    
    },
    {
        title: 'main.36',
        text: 'main.37'    
    },
    {
        title: 'main.38',
        text: 'main.39'    
    },
    {
        title: 'main.40',
        text: 'main.41'    
    },
    {
        title: 'main.42',
        text: 'main.43'    
    },
    {
        title: 'main.44',
        text: 'main.45'    
    },
]

function WhyChoose () {

    const { t } = useTranslation();
      
    return(
        <div className="bg-[#000C17] relative z-50 mt-[-5px] overflow-hidden">
            <div className="max-w-[1920px] mx-auto">
                <img src={footer} alt="" className="absolute w-full h-full object-cover"/>
                <div className="max-w-[1280px] mx-[20px] xl:mx-auto pt-[80px] xl:pt-[160px] relative flex flex-col">
                    <h1 className="russo-one text-white text-[20px] md:text-[24px] xl:text-[48px]">{t('main.33')}</h1>
                    <div className="grid grid-cols-1 xl:grid-cols-2 mt-[60px] xl:gap-x-[200px] gap-y-[20px] xl:gap-y-[40px] justify-between">
                        {data.map((text, index) => (
                            <div className="flex items-start" key={index}>
                                <img src={check} alt="" className="mt-[5px]"/>
                                <div className="ml-[20px]">
                                    <p className="text-[14px] xl:text-[16px] text-[#00FFF0] russo-one uppercase">{t(text.title)}</p>
                                    <p className="mt-[12px] max-w-[100%] text-[14px] xl:text-[16px] text-white font-[300]">{t(text.text)}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default WhyChoose;