import React, { useState } from 'react';

import button from '../../img/MarginTrading/ic_round-plus.svg'

import water_l from '../../img/MarginTrading/water-left.png'
import water_r from '../../img/MarginTrading/water-right.png'

import Footer from "../Footer";

import { useTranslation } from "react-i18next";

const faqData = [
    {
        question: 'margintrading.22',
        answer: 'margintrading.23'
    },
    {
        question: 'margintrading.24',
        answer: 'margintrading.25'
    },
    {
        question: 'margintrading.26',
        answer: 'margintrading.27'
    },
    {
        question: 'margintrading.28',
        answer: 'margintrading.29'
    },
    {
        question: 'margintrading.30',
        answer: 'margintrading.31'
    },
]


function MarginFAQs () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(0);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return(
        <div className="bg-[#000C17] relative pt-[100px] xl:pt-[400px] mt-[-5px] pb-[5px] overflow-hidden">
            <div className='max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col'>
                <h1 className="russo-one text-white text-[20px] md:text-[24px] xl:text-[48px] mx-auto xl:pb-[40px]">{t('margintrading.21')}</h1>
                <img src={water_l} alt="" className='hidden xl:block absolute moving-iceberg -left-1/3'/>
                <img src={water_r} alt="" className='hidden xl:block absolute moving-iceberg -right-1/3'/>
                {faqData.map((faq, index) => (
                    <div key={index} className='xl:mx-auto flex relative'>
                        <div className={`xl:w-[910px] w-screen rounded-[20px] flex justify-between xl:items-start mt-[20px] transition-all duration-300 ${openIndex === index ? 'h-auto bg-[#03192F]' : 'bg-[#03192F]  h-[80px] items-center'}`}>
                            <div className='mx-[25px]'>
                                <p className={`xl:mt-[28px] ${openIndex === index ? 'mt-[30px]' : ''} manrope text-[14px] xl:text-[16px] text-white opacity-[0.7] max-w-[250px] xl:max-w-[1280px]`}>{t(faq.question)}</p>
                                {openIndex === index && (
                                <p className='text-white opacity-[0.7] roboto text-[14px] xl:text-[16px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[802px]'>{t(faq.answer)}</p>
                                )}
                            </div>
                            <div className='cursor-pointer mx-[25px] xl:mt-[20px]' onClick={() => toggleText(index)}>
                                <img src={button} alt="" className={`${openIndex === index ? 'transition-transform rotate-45 mt-[23px]' : 'transition-transform'}`}/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Footer/>
        </div>
    );
}

export default MarginFAQs;