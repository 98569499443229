import React, { useEffect } from "react";

import Main from "../components/TradingInstruments /Main";
import Switcher from "../components/TradingInstruments /Switcher";


function TradingInstruments () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#000C17]">
            <Main/>
            <Switcher/>
        </div>
    );
}

export default TradingInstruments;