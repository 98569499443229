import React, { useEffect } from "react";

import LazyLoad from "react-lazyload";

import Main from "../components/Home/Main";
import BuyAndSelling from "../components/Home/BuyAndSelling";
import ExploreTradeIdeas from "../components/Home/ExploreTradeIdeas";
import Swiper from "../components/Home/Swiper";
import WhyChoose from "../components/Home/WhyChoose";

function Home () {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div className="bg-[#000C17]">
            <Main/>
            <LazyLoad><BuyAndSelling/></LazyLoad>
            <div className="pt-[80px] xl:pt-[160px] bg-[#000C17] mt-[-5px]"></div>
            <LazyLoad><ExploreTradeIdeas/></LazyLoad>
            <div className="pt-[80px] xl:pt-[160px] bg-[#000C17] mt-[-5px]"></div>
            <LazyLoad><Swiper/></LazyLoad>
            <LazyLoad><WhyChoose/></LazyLoad>
        </div>
    );
}

export default Home;